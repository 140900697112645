import paths from "~/constants/paths";
import { useCartStore } from "~/store/Features/Checkout/cartStore";

export default defineNuxtRouteMiddleware(() => {
  const cartStore = useCartStore();
  const { isInAppBrowser } = useInAppBrowser();

  if (cartStore.isCartEmpty && !isInAppBrowser.value) {
    return navigateTo(paths.cart);
  }
});
